.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.message.user {
  align-self: flex-end;
  background-color: #f0f0f0;
}

.message.maya {
  align-self: flex-start;
  background-color: #454545;
  color: white;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
}

.input-container input {
  flex: 1;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-container button {
  padding: 8px 16px;
  margin-left: 10px;
  font-size: 16px;
  /* background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer; */
}


.red-text {
  color: red; /* Set text color to red */
  float: right;
}

